<template>
  <admin-layout isLayout="searchIdeas">
    <IdeaHeader isLayout="searchIdeas" title="Fikirler" />
    <IdeaForm
      class="idea-search-container"
      :searchText="searchText"
      @search-filter="ideaSearchFilter"
      @clear-search-filter="clearSearchFilter"
      isIdea="search-idea"
      isForm="idea"
      suggestionTypeId="1"
    />
    <div style="margin: 0 15px" class="idea-search-table-container">
      <AdminTable
        :columns="columns"
        :rows="getIdea"
        @table-detail="cardDetail"
      />
      <div class="row mt-4 d-flex align-items-center justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRecords"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
      </div>
    </div>
    <transition name="fade">
      <IdeaModal
        :detailData="ideaModalData"
        v-if="isIdeaModal"
        @close="isIdeaModal = $event"
      />
    </transition>
  </admin-layout>
</template>

<script>
import { mapGetters } from "vuex";
import { upload } from "../../services";

import AdminTable from "../../components/Admin/AdminTable.vue";
import IdeaForm from "../../components/MyIdea/IdeaForm.vue";
import IdeaHeader from "../../components/MyIdea/IdeaHeader.vue";
import IdeaModal from "../../components/MyIdea/Modals/IdeaModal.vue";
import AdminLayout from "../../layouts/AdminLayout.vue";
export default {
  components: { AdminLayout, IdeaHeader, IdeaForm, AdminTable, IdeaModal },
  name: "Idea",
  data() {
    return {
      ideaModalData: null,
      isIdeaModal: false,
      columns: [
        {
          label: "Fikir No.",
          field: "SuggestionNumber",
          sortable: false,
          thClass: "vgt-center-align",
          tdClass: "vgt-center-align",
        },
        {
          label: "Fikir Sahibi",
          field: "CreatedCustomerName",
          sortable: false,
          thClass: "vgt-center-align",
          tdClass: "vgt-center-align",
        },
        {
          label: "Sorun/Fırsat",
          field: "Problem",
          sortable: false,
          thClass: "vgt-center-align",
          tdClass: "vgt-center-align",
        },
        {
          label: "Fikir",
          field: "SuggestionDetail",
          sortable: false,
          thClass: "vgt-center-align",
          tdClass: "vgt-center-align",
        },
        {
          label: "Fikir Tarihi",
          field: "SuggestionDate",
          sortable: false,
          thClass: "vgt-center-align",
          tdClass: "vgt-center-align",
        },
        {
          label: "Statü",
          field: "SuggestionStatusName",
          sortable: false,
          thClass: "vgt-center-align",
        },
      ],
      searchText: "",
      perPage: 15,
      currentPage: 1,
      ideaFilterData: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
      totalRecords: "ideas/ideaTotalRecords",
    }),
    getIdea() {
      return this.$store.getters["ideas/getIdeaData"];
    },
  },
  watch: {
    currentPage() {
      this.searchFilter(this.ideaFilterData);
    },
  },
  methods: {
    clearSearchFilter(event) {
      this.searchFilter(event);
    },
    ideaSearchFilter(event) {
      this.currentPage = 1;
      this.searchFilter(event);
    },
    searchFilter(event) {
      let tmpData = null;
      this.ideaFilterData = event;
      tmpData = {
        ...event,
        ...{
          pageSize: this.perPage,
          pageIndex: parseInt(this.currentPage - 1),
          loginUserId: this.user.Data.UserId,
          EndUser: true
        },
      };
      this.$store.dispatch("ideas/deleteIdeaData");
      this.$store.dispatch("ideas/setIdeaData", tmpData);
    },
    async cardDetail(item) {
      this.$store.dispatch("loading/setIsLoading", true);
      this.ideaModalData = item;
      this.ideaModalData.ProjectPresentationDocumentFiles = [];
      this.ideaModalData.DownloadFile = [];

      if (this.ideaModalData.FileIds && this.ideaModalData.FileIds.length > 0) {
        for await (let element of this.ideaModalData.FileIds) {
          const res = await upload.fetchDownloadFile(element);
          this.ideaModalData.DownloadFile.push({
            DownloadBinary: res.Data.DownloadBinary,
            Extension: res.Data.Extension,
            Filename: res.Data.Filename,
            Id: res.Data.Id,
          });
        }
      }
      if (
        this.ideaModalData.ProjectPresentationDocumentIds &&
        this.ideaModalData.ProjectPresentationDocumentIds.length > 0
      ) {
        for await (let element of this.ideaModalData
          .ProjectPresentationDocumentIds) {
          const res = await upload.fetchDownloadFile(element);
          this.ideaModalData.ProjectPresentationDocumentFiles.push({
            DownloadBinary: res.Data.DownloadBinary,
            Extension: res.Data.Extension,
            Filename: res.Data.Filename,
            Id: res.Data.Id,
          });
        }
      }
      this.$store.dispatch("loading/setIsLoading", false);
      this.isIdeaModal = true;
    },
  },
  async created() {
    this.searchText = this.$route.query.searchText;
    if (this.getIdea.length == 0 || this.searchText != "") this.searchFilter();
  },
};
</script>

<style></style>
